// src/pages/IntradayDollarInfoPage.js

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Button, Radio, RadioGroup } from '@blueprintjs/core'; 
import IntradayRecordsTable from '../components/tables/IntradayRecordsTable';
import FirstLeftTable from '../components/tables/FirstLeftTable';
import SecondLeftTable from '../components/tables/SecondLeftTable';
import CandleStickChart from '../components/charts/CandleStickChart';
import AlertasModal from '../components/modales/AlertasModal'; 
import * as con from "./../GlobalConstants";
import { RefRole, RefToken } from '../components/hooks/AuthenticationHook';
import { RefUserAttributesHook } from '../components/hooks/UserAttributesHook';
import { getFinanceRequestConfig } from "../store/logic/supportFunctions";
import { updateSpot } from '../utils/marketFunctions';

const calculateFontSize = (width) => {
  if (width <= 1480) return '4';
  if (width <= 1920) return '10px';
  return '12px';
};

const config = getFinanceRequestConfig();

function IntradayDollarInfoPage() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fontSize, setFontSize] = useState('12px');
  const [selectedPage, setSelectedPage] = useState('resumen');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alerts, setAlerts] = useState([]);

  const user = RefUserAttributesHook('user');
  const token = RefToken();
  const role = RefRole;        
  useEffect(() => {
    const adjustStyles = () => {
      const width = window.innerWidth;
      setFontSize(calculateFontSize(width));
    };
    adjustStyles();
    window.addEventListener('resize', adjustStyles);
    return () => {
      window.removeEventListener('resize', adjustStyles);
    };
  }, []);

  // Fetch data principal (intraday)
  const fetchIntradayData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${con.rest_finance_url}${con.intraday_dollar_info}`,
        config
      );
      setData(response.data);
      updateSpot();
    } catch (error) {
      console.error('Error fetching data:', error);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchAlerts = useCallback(async () => {
    if (user && token) {
      try {
        const response = await axios.get(
          `${con.rest_base_url}${con.transactions_api}${con.usdcop_alerts_null_reached}${user}/`,
          { headers: { Authorization: `Token ${token}` } }
        );
        setAlerts(response.data);
      } catch (error) {
        console.error('Error fetching alerts:', error);
      }
    } else {
      setAlerts([]);
    }
  }, [user, token]);

  useEffect(() => {
    fetchIntradayData();
  }, []);

  useEffect(() => {
    fetchAlerts();
  }, [fetchAlerts]);

  const handleOpenModal = () => {   
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return <div>Cargando...</div>;
  }
  if (!data) {
    return <div>No se pudo cargar la información intradía del dólar.</div>;
  }

  const isMarketClosed = data.market_status === 'closed';
  const isNotViewer = role !== con.VIEWER;  

  return (
    <div style={{ padding: '20px', width: '100%', position: 'relative' }}>
      {isMarketClosed && (
        <div 
          style={{ 
            position: 'absolute',
            top: '10px', 
            left: '25%',
            transform: 'translateX(-50%)', 
            backgroundColor: 'rgba(255, 0, 0, 0.8)',
            color: 'white',
            padding: '10px',
            fontSize: '16px',
            zIndex: 1,
            borderRadius: '5px', 
            marginBottom: '10px'
          }}
        >
          El mercado está cerrado
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', gap: '120px' }}>
        <Button 
          text="Actualizar" 
          onClick={fetchIntradayData} 
        />
        {isNotViewer && (    
          <Button 
            text="Alertas" 
            icon="notifications" 
            onClick={handleOpenModal} 
            disabled={!user || !token}
          />
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
        <RadioGroup
          inline
          selectedValue={selectedPage}
          onChange={(e) => setSelectedPage(e.currentTarget.value)}
        >
          <Radio value="resumen" label="Resumen del dólar actual" />
          <Radio value="cotizaciones" label="Cotizaciones intraday" />
        </RadioGroup>
      </div>

      {selectedPage === 'resumen' && (
        <div>
          <FirstLeftTable data={data} fontSize={fontSize} />
          <SecondLeftTable data={data} fontSize={fontSize} />
          <CandleStickChart data={data.intraday_data} alerts={alerts} />
        </div>
      )}

      {selectedPage === 'cotizaciones' && (
        <div>
          {data.intraday_data ? (
            <IntradayRecordsTable data={data.intraday_data} fontSize={fontSize} />
          ) : (
            <div>No hay datos intradía disponibles.</div>
          )}
        </div>
      )}

      {/* Modal de alertas manual */}
      <AlertasModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        alerts={alerts}
        setAlerts={setAlerts}
      />
    </div>
  );
}

export default IntradayDollarInfoPage;
